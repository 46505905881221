import { gql } from "../../apis/__generated__";

export const GET_OWNERSHIPS = gql(/* GraphQL */ `
  query ownerships(
    $first: Int
    $orderBy: Ownership_orderBy
    $orderDirection: OrderDirection
    $skip: Int
    $where: Ownership_filter
  ) {
    ownerships(
      first: $first
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      where: $where
    ) {
      id
      owner
      index
      amount
      lockupDeadline
      status
    }
  }
`);
