import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import {
  OrderDirection,
  Ownership,
  Ownership_OrderBy,
} from "../../../../../apis/__generated__/graphql";
import useMetadata from "../../../../../hooks/useMetadata";
import { formatEther } from "ethers";
import StatusChip from "../../../../shared/StatusChip";
import WithdrawButton from "../buttons/WithdrawButton";
import { OwnershipTypes } from "../../../../../libraries/utils/constants";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_OWNERSHIPS } from "../../../../../libraries/queries/ownerships";

export interface OwnershipTableProps {
  refetchBalance: () => void;
}

export default function OwnershipTable({
  refetchBalance,
}: OwnershipTableProps) {
  const [selectedRow, setSelectedRow] = useState<Ownership | undefined>(
    undefined
  );
  const { symbol } = useMetadata();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [sortModel, setSortModel] = useState<GridSortModel>();

  const statusFilter = filterModel?.items.find(
    (item) => item.field === "status"
  )?.value;

  const ownerFilter = filterModel?.items.find((item) => item.field === "owner")
    ?.value;

  const {
    data,
    loading,
    refetch: refetchOwnerships,
  } = useQuery(GET_OWNERSHIPS, {
    variables: {
      first: paginationModel.pageSize,
      skip: paginationModel.pageSize * paginationModel.page,
      orderDirection:
        sortModel?.[0]?.sort === "asc"
          ? OrderDirection.Asc
          : OrderDirection.Desc,
      orderBy: sortModel?.[0]?.field as Ownership_OrderBy,

      where: {
        status_in: statusFilter
          ? [statusFilter as OwnershipTypes]
          : [
              OwnershipTypes.PROVISIONAL,
              OwnershipTypes.VESTING_LOCKUP,
              OwnershipTypes.OWNERSHIP_LOCKUP,
            ],
        owner_contains: ownerFilter,
      },
    },
  });

  const ownerships = data?.ownerships || [];
  const refetch = () => {
    refetchOwnerships();
    refetchBalance();
  };

  const onPaginationModelChange = useCallback<
    NonNullable<DataGridProProps["onPaginationModelChange"]>
  >((model) => {
    setPaginationModel(model);
  }, []);

  const onFilterModelChange = useCallback<
    NonNullable<DataGridProProps["onFilterModelChange"]>
  >(
    (model) => {
      setFilterModel(model);
    },
    [setFilterModel]
  );

  const onSortModelChange = useCallback<
    NonNullable<DataGridProProps["onSortModelChange"]>
  >(
    (model) => {
      setSortModel(model);
    },
    [setSortModel]
  );

  const columns: GridColDef[] = [
    {
      field: "index",
      headerName: "ID",
      valueGetter: ({ value }) => {
        return `#${value}`;
      },
      width: 70,
      filterable: false,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 0.3,
      minWidth: 120,
      filterable: true,
    },
    {
      field: "amount",
      headerName: `Amount (${symbol})`,
      minWidth: 120,
      type: "number",
      valueGetter: ({ value }) => formatEther(value),
      flex: 0.15,
      filterable: false,
    },
    {
      field: "lockupDeadline",
      headerName: "Lockup Deadline",
      renderCell: ({ value }) => {
        return Number(value) == 0
          ? "NOT SET"
          : dayjs(value).format("MM/DD/YYYY, HH:mm A");
      },
      minWidth: 200,
      type: "date",
      valueGetter: ({ value }) => dayjs.unix(value).toDate(),
      valueFormatter: ({ value }) => {
        return Number(value) == 0
          ? "NOT SET"
          : dayjs(value).format("MM/DD/YYYY, HH:mm A");
      },
      filterable: false,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      type: "singleSelect",
      valueOptions: [
        { value: OwnershipTypes.PROVISIONAL, label: "PROVISIONAL" },
        { value: OwnershipTypes.OWNERSHIP_LOCKUP, label: "OWNERSHIP_LOCKUP" },
        { value: OwnershipTypes.VESTING_LOCKUP, label: "VESTING_LOCKUP" },
      ],
      renderCell: ({ value }) => {
        return <StatusChip status={value} />;
      },
      filterable: true,
    },
    {
      field: "actions",
      type: "actions",
      width: 50,
      getActions: ({ row }) => [
        <WithdrawButton
          key="Withdraw"
          disabled={
            (row.status !== OwnershipTypes.VESTING_LOCKUP &&
              row.status !== OwnershipTypes.OWNERSHIP_LOCKUP) ||
            dayjs.unix(row.lockupDeadline).isAfter(dayjs()) ||
            row.lockupDeadline == 0
          }
          onSelectedRow={() => setSelectedRow(row)}
          onUnselectedRow={() => setSelectedRow(undefined)}
          row={selectedRow}
          refetch={refetch}
        />,
      ],
    },
  ];

  useEffect(() => {
    setPaginationModel({
      pageSize: 10,
      page: 0,
    });
  }, [filterModel, sortModel]);

  return (
    <Paper>
      <DataGridPro
        sx={{
          ".MuiTablePagination-displayedRows": {
            display: "none",
          },
        }}
        pagination
        rows={ownerships}
        columns={columns}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowCount={
          ownerships.length === 10
            ? paginationModel.pageSize * (paginationModel.page + 1) + 1
            : paginationModel.pageSize * (paginationModel.page + 1)
        }
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        loading={loading}
        autoHeight
        paginationModel={paginationModel}
        sortModel={sortModel}
        filterModel={filterModel}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        slots={{
          toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarExport
                printOptions={{ disableToolbarButton: true }}
              />
            </GridToolbarContainer>
          ),
        }}
      />
    </Paper>
  );
}
