/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  query ownerships(\n    $first: Int\n    $orderBy: Ownership_orderBy\n    $orderDirection: OrderDirection\n    $skip: Int\n    $where: Ownership_filter\n  ) {\n    ownerships(\n      first: $first\n      orderBy: $orderBy\n      orderDirection: $orderDirection\n      skip: $skip\n      where: $where\n    ) {\n      id\n      owner\n      index\n      amount\n      lockupDeadline\n      status\n    }\n  }\n": types.OwnershipsDocument,
    "\n  query cancelLockupProposals($where: CancelLockupProposal_filter) {\n    cancelLockupProposals(where: $where) {\n      id\n      owner\n      index\n      administratorApproved\n      keyKeeperApproved\n      tokenOwnerApproved\n      status\n    }\n  }\n": types.CancelLockupProposalsDocument,
    "\n  query extendLockupPeriodProposals($where: ExtendLockupPeriodProposal_filter) {\n    extendLockupPeriodProposals(where: $where) {\n      id\n      owner\n      index\n      extensionPeriod\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n": types.ExtendLockupPeriodProposalsDocument,
    "\n  query grantKeyKeeperProposals($where: GrantKeyKeeperProposal_filter) {\n    grantKeyKeeperProposals(where: $where) {\n      id\n      newKeyKeeper\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n": types.GrantKeyKeeperProposalsDocument,
    "\n  query revokeKeyKeeperProposals($where: RevokeKeyKeeperProposal_filter) {\n    revokeKeyKeeperProposals(where: $where) {\n      id\n      keyKeeper\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n": types.RevokeKeyKeeperProposalsDocument,
    "\n  query transferOwnershipProposals($where: TransferOwnershipProposal_filter) {\n    transferOwnershipProposals(where: $where) {\n      id\n      newOwner\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n": types.TransferOwnershipProposalsDocument,
    "\n  query transferTokenOwnershipProposals(\n    $where: TransferTokenOwnershipProposal_filter\n  ) {\n    transferTokenOwnershipProposals(where: $where) {\n      id\n      owner\n      index\n      newOwner\n      status\n    }\n  }\n": types.TransferTokenOwnershipProposalsDocument,
    "\n  query upgradeableProposals($where: UpgradeableProposal_filter) {\n    upgradeableProposals(where: $where) {\n      id\n      newImplementation\n      ownerApproved\n      keyKeeperApproved\n      status\n    }\n  }\n": types.UpgradeableProposalsDocument,
    "\n  query roles($first: Int, $skip: Int, $where: Role_filter) {\n    roles(first: $first, skip: $skip, where: $where) {\n      id\n      account\n    }\n  }\n": types.RolesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query ownerships(\n    $first: Int\n    $orderBy: Ownership_orderBy\n    $orderDirection: OrderDirection\n    $skip: Int\n    $where: Ownership_filter\n  ) {\n    ownerships(\n      first: $first\n      orderBy: $orderBy\n      orderDirection: $orderDirection\n      skip: $skip\n      where: $where\n    ) {\n      id\n      owner\n      index\n      amount\n      lockupDeadline\n      status\n    }\n  }\n"): (typeof documents)["\n  query ownerships(\n    $first: Int\n    $orderBy: Ownership_orderBy\n    $orderDirection: OrderDirection\n    $skip: Int\n    $where: Ownership_filter\n  ) {\n    ownerships(\n      first: $first\n      orderBy: $orderBy\n      orderDirection: $orderDirection\n      skip: $skip\n      where: $where\n    ) {\n      id\n      owner\n      index\n      amount\n      lockupDeadline\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query cancelLockupProposals($where: CancelLockupProposal_filter) {\n    cancelLockupProposals(where: $where) {\n      id\n      owner\n      index\n      administratorApproved\n      keyKeeperApproved\n      tokenOwnerApproved\n      status\n    }\n  }\n"): (typeof documents)["\n  query cancelLockupProposals($where: CancelLockupProposal_filter) {\n    cancelLockupProposals(where: $where) {\n      id\n      owner\n      index\n      administratorApproved\n      keyKeeperApproved\n      tokenOwnerApproved\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query extendLockupPeriodProposals($where: ExtendLockupPeriodProposal_filter) {\n    extendLockupPeriodProposals(where: $where) {\n      id\n      owner\n      index\n      extensionPeriod\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n"): (typeof documents)["\n  query extendLockupPeriodProposals($where: ExtendLockupPeriodProposal_filter) {\n    extendLockupPeriodProposals(where: $where) {\n      id\n      owner\n      index\n      extensionPeriod\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query grantKeyKeeperProposals($where: GrantKeyKeeperProposal_filter) {\n    grantKeyKeeperProposals(where: $where) {\n      id\n      newKeyKeeper\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n"): (typeof documents)["\n  query grantKeyKeeperProposals($where: GrantKeyKeeperProposal_filter) {\n    grantKeyKeeperProposals(where: $where) {\n      id\n      newKeyKeeper\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query revokeKeyKeeperProposals($where: RevokeKeyKeeperProposal_filter) {\n    revokeKeyKeeperProposals(where: $where) {\n      id\n      keyKeeper\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n"): (typeof documents)["\n  query revokeKeyKeeperProposals($where: RevokeKeyKeeperProposal_filter) {\n    revokeKeyKeeperProposals(where: $where) {\n      id\n      keyKeeper\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query transferOwnershipProposals($where: TransferOwnershipProposal_filter) {\n    transferOwnershipProposals(where: $where) {\n      id\n      newOwner\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n"): (typeof documents)["\n  query transferOwnershipProposals($where: TransferOwnershipProposal_filter) {\n    transferOwnershipProposals(where: $where) {\n      id\n      newOwner\n      administratorApproved\n      keyKeeperApproved\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query transferTokenOwnershipProposals(\n    $where: TransferTokenOwnershipProposal_filter\n  ) {\n    transferTokenOwnershipProposals(where: $where) {\n      id\n      owner\n      index\n      newOwner\n      status\n    }\n  }\n"): (typeof documents)["\n  query transferTokenOwnershipProposals(\n    $where: TransferTokenOwnershipProposal_filter\n  ) {\n    transferTokenOwnershipProposals(where: $where) {\n      id\n      owner\n      index\n      newOwner\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query upgradeableProposals($where: UpgradeableProposal_filter) {\n    upgradeableProposals(where: $where) {\n      id\n      newImplementation\n      ownerApproved\n      keyKeeperApproved\n      status\n    }\n  }\n"): (typeof documents)["\n  query upgradeableProposals($where: UpgradeableProposal_filter) {\n    upgradeableProposals(where: $where) {\n      id\n      newImplementation\n      ownerApproved\n      keyKeeperApproved\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query roles($first: Int, $skip: Int, $where: Role_filter) {\n    roles(first: $first, skip: $skip, where: $where) {\n      id\n      account\n    }\n  }\n"): (typeof documents)["\n  query roles($first: Int, $skip: Int, $where: Role_filter) {\n    roles(first: $first, skip: $skip, where: $where) {\n      id\n      account\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;